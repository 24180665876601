import React from "react";
import styled from "styled-components";
import * as Unicons from "@iconscout/react-unicons";
import AOS from "aos";
import "aos/dist/aos.css";
import { scrollToSection } from "../helpers/utilities";

const MobileNav = ({ OpenMobile, onCloseMobile }) => {
  if (!OpenMobile) return null;

  const handleClick = (sectionId) => {
    scrollToSection(sectionId);
    onCloseMobile();
  };

  var mobile;
  return (
    <Overlay>
      <Mobile>
        <a className="exit" onClick={onCloseMobile}>
          {" "}
          <Unicons.UilX size="4rem" color="var(--Primary-White)" />
        </a>

        <NavBar>
          <NavItem>
            <a onClick={() => handleClick("AboutMe")}>About Me</a>
          </NavItem>
          <NavItem>
            <a onClick={() => handleClick("Projects")}>Projects</a>
          </NavItem>
          <NavItem>
            <a onClick={() => handleClick("Skills")}>Skills</a>
          </NavItem>
          <NavItem>
            <a onClick={() => handleClick("ContactMe")}>Contact Me</a>
          </NavItem>
        </NavBar>
      </Mobile>
    </Overlay>
  );
};

export default MobileNav;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--Primary-Blue);
  z-index: 200;
`;

const Mobile = styled.div`
  transition: transform 0.3s ease-in-out;
  height: 100vh;
  width: 100vw;
  .exit {
    @media (min-width: 721px) {
      :hover {
        transform: scale(1.1);
      }
    }
  }
`;

const NavBar = styled.div`
  height: 100vh;
  width: 100vw;
  font-size: 2.5rem;
  gap: 2rem;
  font-family: var(--Eina-Bold);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--Primary-White);
`;
const NavItem = styled.div`
  position: relative;
  text-decoration: none;
  color: var(--Primary-White);
  transition: transform 0.3s ease-in-out;

  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--Primary-White);
    transition: width 0.3s ease-in-out;
  }

  @media (min-width: 721px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;
