import "./App.css";
import React, { useState, useEffect } from "react";
import Hero from "./components/hero.js";
import AboutMe from "./components/aboutMe.js";
import Projects from "./components/projects.js";
import Skills from "./components/skills.js";
import Footer from "./components/footer.js";
import Nav from "./components/nav.js";
import useModal from "./modals/useModal.js";
import Modal from "./components/modal.js";
import styled from "styled-components";
import MobileNav from "./components/MobileNav";
import * as Unicons from "@iconscout/react-unicons";

function App() {
  // Use effect and state for popup
  const { openModal, setOpenModal } = useModal();
  const [modalData, setModalData] = useState({});
  // open popup modal
  const openModalHandler = (
    description,
    image,
    title,
    subTitle,
    primaryColor,
    btn1,
    btn1link,
    btn2,
    btn2link,
    tech1,
    tech2,
    tech3
  ) => {
    setModalData({
      description,
      image,
      title,
      subTitle,
      primaryColor,
      btn1,
      btn1link,
      btn2,
      btn2link,
      tech1,
      tech2,
      tech3,
    });
    setOpenModal(true);
  };

  // Use state to show nav bar
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 920) {
        setShowNav(false);
      } else {
        setShowNav(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [openMobileNav, setOpenMobileNav] = useState(false);
  console.log(openMobileNav);
  return (
    <div className="App">
      {showNav == true ? (
        <Nav />
      ) : (
        <div>
          <div onClick={() => setOpenMobileNav(true)}>
            <Unicons.UilBars size="4rem" color="var(--Primary-Blue)" />
          </div>

          <MobileNav
            OpenMobile={openMobileNav}
            onCloseMobile={() => setOpenMobileNav(false)}
          />
        </div>
      )}
      <Container>
        <Hero />

        <AboutMe />
        <div>
          {/* <button }></button> */}
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            ModalDataProps={modalData}
          />
        </div>
        <Projects
          onOpen={() => setOpenModal(true)}
          openModalHandlerProp={openModalHandler}
        />
        <Skills />
      </Container>
      <Footer />
    </div>
  );
}

export default App;

const Container = styled.div`
  width: 100vw !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto;
  overflow: hidden;
`;
